import React from "react";
import Layout, { Main } from "../wrappers/layout";
import { useStoreContext } from "../wrappers/store";
import useSettings from "../hooks/useSettings";
import { PriceTag } from "../components/pricing";
import { LineItemQuantity } from "../components/quantity"
import { ShopifyImage } from "../components/image"
import Link from "../components/link"
import RichText from "../components/rich-text";

function CheckoutButton ({ url }) {
	return (
		<a href={url} className="btn h1">Proceed to checkout</a>
	)
}

function LineItem (props) {

	const {
		id,
		quantity,
		merchandise: {
			product,
			price,
			selectedOptions,
			quantityAvailable
		}
	} = props

	return (
		<div className="line-item has-gaps">
			<Link to={`/products/${product.handle}`} className="line-item__thumbnail">
				<ShopifyImage {...product?.featuredImage} format="auto" />
			</Link>
			<div className="line-item__info has-gaps">
				<div className="line-item__details">
					<hgroup>
						<h1>{product?.title?.replace(product?.vendor, "")}</h1>
						<em>{product?.vendor}</em>
						<h2><PriceTag currencyCode={price?.currencyCode} amount={price?.amount} /></h2>
					</hgroup>
					<div className="outerx2">
						{selectedOptions?.map((option) => 
							option.name !== 'Title' ? (
								<h5 className="outer" key={option.name}>
									{option.name}: {option.value}
								</h5>
							) : null
						)}
					</div>
				</div>
				<div className="line-item__actions">
					<LineItemQuantity 
						lineItemId={id} 
						quantity={quantity} 
						quantityAvailable={quantityAvailable} 
					/>
				</div>
			</div>
		</div>
	)
}

export default function CartPage () {

	const { cart } = useStoreContext()
	const { cartSmallprint } = useSettings()

	return (
		<Layout>
			<Main className="page-gutters outerx2">
				<div className="grid has-gaps">
					<div className="span-8">
						{cart?.lines?.edges?.map((item) => 
							<LineItem {...item.node} key={item.node.id} />
						)}
					</div>
					{cart && (
						<aside className="span-4">
							<div className="inner bdr-b flex-spaced">
								<h1>Cart subtotal:</h1>
								<h2>
									<PriceTag 
										currencyCode={cart?.cost?.totalAmount?.currencyCode} 
										amount={cart?.cost?.totalAmount?.amount} 
									/>
								</h2>
							</div>
							<div className="outerx2 has-links cart-smallprint">
								<RichText content={cartSmallprint} />
							</div>
							<div className="outerx4">
								<CheckoutButton url={cart?.checkoutUrl} />
							</div>
						</aside>
					)}
				</div>
			</Main>
		</Layout>
	)
}