import React from "react";

export const formatPrice = (currency, value) => {
	if (currency && value) {
		return Intl.NumberFormat("en-GB", {
			currency,
			minimumFractionDigits: 2,
			style: "currency",
		}).format(value)
	} else {
		return "…"
	}
}

export const getCurrencySymbol = (currency, locale = undefined) => {
 if (!currency) {
	 return
 }

 const formatter = Intl.NumberFormat(locale, {
	 currency,
	 style: "currency",
 })
 const parts = formatter.formatToParts(100)
 const { value: symbol } = parts.find((part) => part.type === "currency")
 const formatted = formatter.format(100)
 const symbolAtEnd = formatted.endsWith(symbol)
 return { symbol, symbolAtEnd }
}

export function hasPriceRange (props) {
	if (props?.maxVariantPrice && props?.minVariantPrice) {
		return parseInt(props.maxVariantPrice?.amount, 10) > parseInt(props.minVariantPrice?.amount, 10)
	} else {
		return false
	}
}

export function PriceTag({ currencyCode, amount }) {
	const price = formatPrice(currencyCode, amount)
	return (
		<span>{price}</span>
	)
}

export function PriceRange({ priceRange }) {
	const hasRange = hasPriceRange(priceRange)
	const price = formatPrice(priceRange?.minVariantPrice?.currencyCode, priceRange?.minVariantPrice?.amount)

	return (
		<span>
			{hasRange && <h5 className="inl">{"from "}</h5>} {price}
		</span>
	)
}