// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image" 
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/

function getShopifyImageData({ 
	width=1, 
	height=1, 
	baseUrl="", 
	format="auto"
}) {
	// Split string
	let [basename, version] = baseUrl.split(`?`);
	const dot = basename.lastIndexOf(`.`);
	
	// Get extension
	let ext = ``;
	if (dot !== -1) {
		ext = basename.slice(dot + 1);
		basename = basename.slice(0, dot);
	}
	
	// Get suffix
	let suffix = ``;
	if (format === ext || format === "auto") {
		suffix = `.${ext}`;
	} else {
		suffix = `.${ext}.${format}`;
	}

	// Return url
	return `${basename}_${width}x${height}_crop_center${suffix}?${version}`;
}

export function GatsbyImage (props) {

	const { width, height, images } = props.data || {}
	const { src, srcSet, sizes } = images?.fallback || {}

	return (
		<img 
			src={src} 
			srcSet={srcSet} 
			sizes={sizes} 
			alt={props.alt || ""} 
			width={width}
			height={height}
			style={props.style}
		/>
	)
}

export function ShopifyImage ({
	width,
	height,
	url
}) {

	const source = getShopifyImageData({
		width,
		height,
		baseUrl: url,
		format: "auto"
	})

	return (
		<img
			src={source}
			width={width} 
			height={height} 
			alt=""
		/>
	)
}

export function MissingImage() {
	return (
		<div className="missing-image" />
	)
}

export function Img (props) {
	return (
		<img alt="" {...props} />
	)
}

/*
	TODO: work out a non-gatsby-plugin way to generate a srcset for responsive image support
	<img 
		src="https://cdn.shopify.com/s/files/1/0579/6626/4380/products/xantharia-throw-crop-724x900_724x900_crop_center.jpg?v=1666022396" 
		srcset="https://cdn.shopify.com/s/files/1/0579/6626/4380/products/xantharia-throw-crop-724x900_181x225_crop_center.jpg?v=1666022396 181w,
		https://cdn.shopify.com/s/files/1/0579/6626/4380/products/xantharia-throw-crop-724x900_362x450_crop_center.jpg?v=1666022396 362w,
		https://cdn.shopify.com/s/files/1/0579/6626/4380/products/xantharia-throw-crop-724x900_724x900_crop_center.jpg?v=1666022396 724w" 
		sizes="(min-width: 724px) 724px, 100vw" 
		alt="" 
		width="1080" 
		height="1343">
		</img>
*/

export const ContentfulMediaFragment = graphql`
	fragment ContentfulMedia on ContentfulAsset {
		id
		file {
			contentType
			url
		}
		description
		large: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
	}

	fragment ShopifyMediaImage on ShopifyProductImage {
		shopifyId
		originalSrc
		width
		height
		small: gatsbyImageData(layout: CONSTRAINED, width: 1080)
		large: gatsbyImageData(layout: FULL_WIDTH)	
		altText
	}
`