import React from "react";
import { useStoreContext } from "../wrappers/store";

// export function useQuantity(initial) {
// 	const [quantity, setQuantity] = useState(initial)
// 	const up = () => setQuantity(prev => prev + 1)
// 	const down = () => setQuantity(prev => prev > 0 ? prev - 1 : 0)
// 	return [quantity, up, down]
// }

export function LineItemQuantity({ lineItemId, quantity, quantityAvailable }) {

	const { updateLineItem } = useStoreContext()

	const hasMoreAvailable = quantity < quantityAvailable

	const increase = () => {
		if (hasMoreAvailable) {
			updateLineItem(lineItemId, quantity + 1)
		}
	}
	const decrease = () => {
		updateLineItem(lineItemId, quantity - 1)
	}
	
	return (
		lineItemId && quantity && (
			<div className="quantity-input">
				<button type="button" className="btn" onClick={decrease}>-</button>
				<span className="btn">{quantity}</span>
				<button type="button" className={`btn`} onClick={increase} disabled={!hasMoreAvailable}>+</button>
			</div>
		)
	)
}